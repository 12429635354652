import React, { useEffect } from 'react'
// import questionSets from './data/fi/questions.json'
import { getAnswerByID } from './utils'
import { useForm } from './contexts/FormContext'

// Components
import Survey from './components/Survey'
import Summary from './components/Summary'
import OnePageSummary from './components/OnePageSummary'
import Footer from './components/Footer'

// Material UI
import Box from '@material-ui/core/Box'
import useStyles from './styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'

import { ReactComponent as Finnish } from './images/flags/fi.svg'
import { ReactComponent as English } from './images/flags/uk.svg'

const App = () => {
	const {
		currentPage,
		setCurrentPage,
		formSubmitted,
		setFormSubmitted,
		onePageForm,
	} = useForm()
	const classes = useStyles()

	const { t: getTranslation, i18n } = useTranslation('questions')
	const questionSets = getTranslation('questions', { returnObjects: true })

	// For one page forms
	let questions = questionSets

	// One page form and regular long form questions and page must be set separately
	if (onePageForm === true) {
		questions = [questionSets[currentPage - 1]]
	}

	// Scroll to top when page is changed
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		})
	}, [currentPage])

	const firstAnswer = getAnswerByID(1, 1) // First question's value

	console.log('f', firstAnswer)

	const handleNextPage = () => {
		setCurrentPage(currentPage + 1)
	}

	const handlePreviousPage = () => {
		// Check if 'Edellinen' needs to skip one page based on first question's answer

		setCurrentPage(currentPage - 1)
	}

	const handleFormSubmit = event => {
		event.preventDefault()
		setFormSubmitted(!formSubmitted)
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		})
	}

	const changeLanguageHandler = lang => {
		i18n.changeLanguage(lang)
	}
	return (
		<Box className={classes.mainBackground}>
			<Box pt={5}>
				<Box align='center' margin m={2} pt={3}>
					{
						// Hide language switch because answer is stored as text not id for some reason
						firstAnswer !== 0 && (
							<div>
								<Button
									onClick={() => changeLanguageHandler('fi')}
									size='small'
									variant='outlined'
									style={{ margin: 2 }}
								>
									Finnish <Finnish style={{ paddingLeft: 5 }} />
								</Button>
								<Button
									onClick={() => changeLanguageHandler('en')}
									size='small'
									variant='outlined'
									style={{ margin: 2 }}
								>
									English <English style={{ paddingLeft: 5 }} />
								</Button>
							</div>
						)
					}
				</Box>
				{/* Was form submitted */}
				{formSubmitted ? (
					//  One page form and regular long form have different summary pages
					onePageForm === false ? (
						<Summary handleFormSubmit={handleFormSubmit} />
					) : (
						<OnePageSummary handleFormSubmit={handleFormSubmit} />
					)
				) : (
					// Form not submitted
					<Survey
						handleFormSubmit={handleFormSubmit}
						handleNextPage={handleNextPage}
						handlePreviousPage={handlePreviousPage}
						questionSets={questions}
						currentPage={currentPage} // page 1-6
						formSubmitted={formSubmitted}
						onePageForm={onePageForm} // only one page must be filled
					/>
				)}
				<Footer />
			</Box>
		</Box>
	)
}

export default App

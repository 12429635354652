import React from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

const values = {
	0: [14, 20, 27],
	1: [12, 18, 24],
	2: [9, 13, 17],
	3: [17, 25, 33],
	4: [12, 18, 24],
	5: [14, 20, 27],
}

export const marketingFeedback = value => {
	const { t: getTranslation } = useTranslation('feedback')
	const feedbackText = getTranslation('marketing', { returnObjects: true })

	if (value >= values[0][2]) {
		return feedbackText['id_1']
	} else if (value >= values[0][1]) {
		return feedbackText['id_2']
	} else if (value >= values[0][0]) {
		return feedbackText['id_3']
	} else {
		return feedbackText['id_4']
	}
}
export const economicFeedback = value => {
	const { t: getTranslation } = useTranslation('feedback')
	const feedbackText = getTranslation('economic', { returnObjects: true })

	if (value >= values[0][2]) {
		return feedbackText['id_1']
	} else if (value >= values[0][1]) {
		return feedbackText['id_2']
	} else if (value >= values[0][0]) {
		return <div>{parse(feedbackText['id_3'])}</div>
	} else {
		return <div>{parse(feedbackText['id_4'])}</div>
	}
}
export const legalFeedback = value => {
	const { t: getTranslation } = useTranslation('feedback')
	const feedbackText = getTranslation('legal', { returnObjects: true })

	if (value >= values[0][2]) {
		return feedbackText['id_1']
	} else if (value >= values[0][1]) {
		return feedbackText['id_2']
	} else if (value >= values[0][0]) {
		return <div>{parse(feedbackText['id_3'])}</div>
	} else {
		return <div>{parse(feedbackText['id_4'])}</div>
	}
}
export const developmentFeedback = value => {
	const { t: getTranslation } = useTranslation('feedback')
	const feedbackText = getTranslation('development', { returnObjects: true })

	if (value >= values[0][2]) {
		return feedbackText['id_1']
	} else if (value >= values[0][1]) {
		return <div>{parse(feedbackText['id_2'])}</div>
	} else if (value >= values[0][0]) {
		return feedbackText['id_3']
	} else {
		return <div>{parse(feedbackText['id_4'])}</div>
	}
}
export const leadershipFeedback = value => {
	const { t: getTranslation } = useTranslation('feedback')
	const feedbackText = getTranslation('leadership', { returnObjects: true })

	if (value >= values[0][2]) {
		return feedbackText['id_1']
	} else if (value >= values[0][1]) {
		return feedbackText['id_2']
	} else if (value >= values[0][0]) {
		return <div>{parse(feedbackText['id_3'])}</div>
	} else {
		return <div>{parse(feedbackText['id_4'])}</div>
	}
}
export const selfInspectionFeedback = value => {
	const { t: getTranslation } = useTranslation('feedback')
	const feedbackText = getTranslation('selfInspection', { returnObjects: true })

	if (value >= values[0][2]) {
		return feedbackText['id_1']
	} else if (value >= values[0][1]) {
		return feedbackText['id_2']
	} else if (value >= values[0][0]) {
		return  feedbackText['id_3']
	} else {
		return feedbackText['id_4']
	}
}

export default marketingFeedback

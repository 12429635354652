import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import useStyles from '../../styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// Images
import entrefox_badge from '../../images/summaryImages/entrefox_badge.png'
/* import entrefox_steps from '../../images/summaryImages/entrefox_steps.png' */
import { useTranslation } from 'react-i18next'
const GoalsScroll = ({ answers }) => {
	const { t: getTranslation } = useTranslation('general')
	const classes = useStyles()
	return (
		<Grid
			key={0}
			container
			className={classes.goalBox}
			justify='center'
			direction='column'
		>
			<Grid container direction='row' item alignItems='center'>
				<Grid item xs={12} md={5}>
					<Box className={classes.badge}>
						<img src={entrefox_badge} />
					</Box>
				</Grid>
			</Grid>

			<Grid item>
				<Box mx={2} my={1}>
					<Typography variant='body1' className={classes.noteText} key={1}>
						<Box component='span' fontWeight='bold'>
							{getTranslation('my_best_skills')}
						</Box>{' '}
						<p>{answers[0].value}</p>
					</Typography>
				</Box>
			</Grid>
			<Grid item>
				<Box mx={2} my={1}>
					<Typography variant='body1' className={classes.noteText} key={2}>
						<Box component='span' fontWeight='bold'>
							{getTranslation('skills_to_improve')}
						</Box>{' '}
						<p>{answers[1].value}</p>
					</Typography>
				</Box>
			</Grid>
		</Grid>
	)

}

GoalsScroll.propTypes = {
	answers: PropTypes.array
}

export default GoalsScroll

import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import useStyles from '../../styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis
} from 'recharts'

// Images
import entrefox_badge from '../../images/summaryImages/entrefox_badge.png'
/* import entrefox_steps from '../../images/summaryImages/entrefox_steps.png'
import { SubscriptionsOutlined } from '@material-ui/icons' */
import { useTranslation } from 'react-i18next'
const SummaryScroll = ({ answers, graphData }) => {
    const { t: getTranslation } = useTranslation('general')

    const classes = useStyles()
    return (
			<Grid
				key={1}
				container
				className={classes.goalBox}
				justify='center'
				direction='column'
			>
				<Grid container direction='row' item alignItems='center'>
					<Grid item xs={12} md={5}>
						<Box className={classes.badge}>
							<img src={entrefox_badge} />
						</Box>
					</Grid>
					<Grid item>
						<Box mx={2} my={1}>
							<Typography
								style={{ textDecorationLine: 'underline' }}
								variant='h4'
							>
								 {getTranslation('summary')}
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '-20px',
					}}
				>
					<Box
						style={{ width: 450, backgroundColor: 'white', borderRadius: 10 }}
					>
						<RadarChart
							/* cx={300}
                        cy={250} */
							outerRadius={150}
							width={450}
							height={400}
							data={graphData}
						>
							<PolarGrid stroke='black' />
							<PolarAngleAxis dataKey='subject' />
							<Radar
								name='Mike'
								dataKey='A'
								stroke='#cddc39'
								fill='#cddc39'
								fillOpacity={0.85}
							/>
						</RadarChart>
					</Box>
				</Box>
				<Box style={{ display: 'flex', justifyContent: 'center' }}>
					<Box
						style={{
							display: 'flex',
							padding: '20px',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						{answers.map((item, index) => {
							return (
								<Typography style={{ fontSize: 20 }} key={index}>
									{item}
								</Typography>
							)
						})}
					</Box>
				</Box>
			</Grid>
		)

}

SummaryScroll.propTypes = {
    answers: PropTypes.string,
    graphData: PropTypes.array
}

export default SummaryScroll

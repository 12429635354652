import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStyles from '../../styles'
import { useTranslation } from 'react-i18next'
const NumberGauge = ({ answer, maxAnswer, title, page }) => {
	const { t: getTranslation } = useTranslation('general')

	const ratios = {
		2: [0.4375, 0.625, 0.843],
		3: [0.45, 0.6, 0.84],
		4: [0.45, 0.65, 0.85],
		5: [0.425, 0.625, 0.825],
		6: [0.428, 0.642, 0.857],
		7: [0.4375, 0.625, 0.843],
	}
	const classes = useStyles()
	const answerRatio = parseFloat((answer / maxAnswer).toFixed(2))
	const rotationValue = 0.5 / answer
	const titleText = () => {
		if (ratios[page][2] <= answerRatio) {
			return getTranslation('grading.many_strengths')
		} else if (ratios[page][1] <= answerRatio) {
			return getTranslation('grading.strengths')
		} else if (ratios[page][0] <= answerRatio) {
			return getTranslation('grading.improve')
		} else {
			return getTranslation('grading.lots_to_improve')
		}
	}
	return (
		<>
			<Box style={{ display: 'flex', flexDirection: 'column' }}>
				<Box textAlign='center' my={2}>
					<Typography variant='h6'>{title}</Typography>
				</Box>
				<Box style={{ width: '450px' }}>
					<Grid
						container
						direction='row'
						justify='center'
						className={classes.gaugeGrid}
					>
						<Grid
							item
							container
							direction='column'
							justify='flex-end'
							alignItems='flex-end'
							xs={1}
						>
							{/* <Box className={classes.gaugeLabel}>
							<Typography variant='h6'>Heikko</Typography>
						</Box> */}
						</Grid>
						<Grid item container direction='column' xs={10}>
							<Box className={classes.gauge}>
								<Box className={classes.gaugeBody}>
									<Box
										className={classes.gaugeFill}
										style={{
											transform: `rotate(${
												rotationValue * answer * answerRatio
											}turn)`,
										}}
									></Box>
									<Box className={classes.gaugeCover}>
										<Typography style={{ fontSize: 16 }} variant='h6'>
											{titleText()}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid
							item
							container
							direction='column'
							justify='flex-end'
							alignItems='flex-start'
							xs={1}
						>
							{/* <Box className={classes.gaugeLabel}>
							<Typography style={{ marginLeft: -30 }} variant='h6'>Erinomainen</Typography>
						</Box> */}
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	)
}

NumberGauge.propTypes = {
	answer: PropTypes.number,
	maxAnswer: PropTypes.number,
	title: PropTypes.string,
	page: PropTypes.number,
}

export default NumberGauge

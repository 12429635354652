import React, { useState, useContext, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import initialFormState from './initialFormState'
import { useTranslation } from 'react-i18next'

const FormContext = createContext(null)

export const useForm = () => {
	const context = useContext(FormContext)

	if (!context) {
		throw new Error('useForm() is used outside of FormContext')
	}

	return context
}

// Update FormContext provider
const FormContextProvider = ({ children }) => {
	const { t: getTranslation } = useTranslation('questions')
	const questions = getTranslation('questions', { returnObjects: true })

	const [formData, setFormData] = useState(initialFormState(questions)) // Form is controlled and data is saved in state
	const [currentPage, setCurrentPage] = useState(1) // Keeping track on visible page
	const [formSubmitted, setFormSubmitted] = useState(false) // Value changes on form submit or 'Palaa takaisin' button
	const formType = parseInt(
		JSON.parse(new URLSearchParams(window.location.search).get('type'))
	)

	let onePageForm = !isNaN(formType) // Did user click a specific form?

	useEffect(() => {
		// Change page if one page form type
		if (onePageForm) {
			setCurrentPage(formType)
		}
	}, []) // Get URL parameter to show only a selected piece of the form

	const handleSubmitChange = event => {
		event.preventDefault()
		setFormSubmitted(!formSubmitted)
	}
	// Update formData state
	const handleInputChange = (id, value) => {
		let [pageID, choiceID] = [0, 0]

		// Separate pageID and choiceID from e.g. 3.4
		if (id.includes('.')) {
			;[pageID, choiceID] = id.split('.')
		} else {
			;[pageID, choiceID] = [id, 0]
		}
		const newData = {
			id: parseInt(pageID),
			choiceID: parseInt(choiceID),
			value,
		}

		// Create a new state variable
		const newState = formData.map(pageToEdit => {
			// Change the value in the page it is located at
			if (pageToEdit.page === currentPage) {
				return {
					...pageToEdit,
					answers: pageToEdit.answers.map(answer =>
						answer.id === newData.id ? newData : answer
					),
				}
			}
			// Return page data (no values need to be changed here)
			return {
				...pageToEdit,
			}
		})
		// Replace the old state with new one
		setFormData(newState)
	}

	// Return all necessary variables and functions for children components to use
	const value = {
		formSubmitted,
		formData,
		currentPage,
		handleSubmitChange,
		handleInputChange,
		setCurrentPage,
		setFormSubmitted,
		onePageForm,
	}

	return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

FormContextProvider.propTypes = {
	children: PropTypes.object,
}

export default FormContextProvider

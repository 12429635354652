import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from '../contexts/FormContext'
import { getAnswerByID } from '../utils'
import {
	marketingFeedback,
	developmentFeedback,
	economicFeedback,
	legalFeedback,
	leadershipFeedback,
	selfInspectionFeedback,
} from '../data/feedback functions'

// Summary components
import ButtonHandler from './ButtonHandler'
import SummaryScroll from './summaryComponents/SummaryPaper'
import NumberGauge from './summaryComponents/NumberGauge'
import GoalsPaper from './summaryComponents/GoalsPaper'

// Material UI
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import useStyles from '../styles'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'

// Images
/* import entrefox_business from '../images/summaryImages/entrefox_business.png' */
import osaamiskartoituksen_koonti from '../images/summaryImages/osaamiskartoitus-koonti.png'
import entrefox_pdf_bg from '../images/background/pdf_background.png'

import html2pdf from 'html2pdf.js'
// import dict from '../data/question_dictionary'
/* import StickyNote from './summaryComponents/StickyNote' */
import { useTranslation } from 'react-i18next'

// Convert date to dd.MM.YYYY format
const dateToYMD = date => {
	return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

const Summary = ({ handleFormSubmit }) => {
	const { t: getTranslation } = useTranslation('general')

	const { formData, currentPage } = useForm()
	const classes = useStyles()
	// console.log('formdata', formData[6].answers)
	/* 	const { formData } = useForm() */
	/* 	const sliderValue = dict[getAnswerByID(2, 1)] */
	// Get todays date

	const currentDate = dateToYMD(new Date())

	// This could be replaced with the code from OnePageSummary.js
	// Returns a sum of all the choiceID's which are then later deducted from the overall sum of the highest choiceID's
	// EXAMPLE: choices=4, questions=8, sum=32(+8 because user cant get 0/32),
	// if user answers all questions with choiceID=1, which is worth 4 points, sum=8*1=8, and the total points the user got is ofc 40-8= 32/32

	// Calculate the amount of points user got from their choices from the sales and marketing questions
	const totalMarketingPoints =
		getAnswerByID(2, 1) +
		getAnswerByID(2, 2) +
		getAnswerByID(2, 3) +
		getAnswerByID(2, 4) +
		getAnswerByID(2, 5) +
		getAnswerByID(2, 6) +
		getAnswerByID(2, 7) +
		getAnswerByID(2, 8)
	// Calculate the amount of points user got from their choices from the economics questions
	const totalEconomicPoints =
		getAnswerByID(3, 1) +
		getAnswerByID(3, 2) +
		getAnswerByID(3, 3) +
		getAnswerByID(3, 4) +
		getAnswerByID(3, 5) +
		getAnswerByID(3, 6) +
		getAnswerByID(3, 7)
	// Calculate the amount of points user got from their choices from the legal knowledge questions
	const totalLegalPoints =
		getAnswerByID(4, 1) +
		getAnswerByID(4, 2) +
		getAnswerByID(4, 3) +
		getAnswerByID(4, 4) +
		getAnswerByID(4, 5)
	//Calculate the amount of points user got from defelopment questions
	const totalDevelopmentPoints =
		getAnswerByID(5, 1) +
		getAnswerByID(5, 2) +
		getAnswerByID(5, 3) +
		getAnswerByID(5, 4) +
		getAnswerByID(5, 5) +
		getAnswerByID(5, 6) +
		getAnswerByID(5, 7) +
		getAnswerByID(5, 8) +
		getAnswerByID(5, 9) +
		getAnswerByID(5, 10)
	//Calculate the amount of points user got from leadership questions
	const totalLeadershipPoints =
		getAnswerByID(6, 1) +
		getAnswerByID(6, 2) +
		getAnswerByID(6, 3) +
		getAnswerByID(6, 4) +
		getAnswerByID(6, 5) +
		getAnswerByID(6, 6) +
		getAnswerByID(6, 7)
	//Calculate the amount of points from self inspection questions
	const totalSelfInspectionPoints =
		getAnswerByID(7, 1) +
		getAnswerByID(7, 2) +
		getAnswerByID(7, 3) +
		getAnswerByID(7, 4) +
		getAnswerByID(7, 5) +
		getAnswerByID(7, 6) +
		getAnswerByID(7, 7) +
		getAnswerByID(7, 8)

	const maxPoints = {
		marketing: (formData[1].answers.length - 1) * 4,
		economic: (formData[2].answers.length - 1) * 4,
		legal: (formData[3].answers.length - 1) * 4,
		development: (formData[4].answers.length - 1) * 4,
		leadership: (formData[5].answers.length - 1) * 4,
		selfInspection: 5 * 3 + 3 * 4, // this has five 3 point choice questions and three worth 4 points.
	}

	const points = {
		marketing:
			maxPoints.marketing -
			totalMarketingPoints +
			formData[1].answers.length -
			1,
		economic:
			maxPoints.economic - totalEconomicPoints + formData[2].answers.length - 1,
		legal: maxPoints.legal - totalLegalPoints + formData[3].answers.length - 1,
		development:
			maxPoints.development -
			totalDevelopmentPoints +
			formData[4].answers.length -
			1,
		leadership:
			maxPoints.leadership -
			totalLeadershipPoints +
			formData[5].answers.length -
			1,
		selfInspection:
			maxPoints.selfInspection -
			totalSelfInspectionPoints +
			formData[6].answers.length -
			1,
	}
	//   console.log(points)
	//   console.log(maxPoints)

	/* data for the radar chart */
	const graphData = [
		{
			subject: getTranslation('marketing.id_1'),
			A: (points.marketing / maxPoints.marketing) * 100,
			fullMark: 100,
		},
		{
			subject: getTranslation('economic.id_1'),
			A: (points.economic / maxPoints.economic) * 100,
			fullMark: 100,
		},
		{
			subject: getTranslation('legal.id_1'),
			A: (points.legal / maxPoints.legal) * 100,
			fullMark: 100,
		},
		{
			subject: getTranslation('development.id_1'),
			A: (points.development / maxPoints.development) * 100,
			fullMark: 100,
		},
		{
			subject: getTranslation('leadership.id_1'),
			A: (points.leadership / maxPoints.leadership) * 100,
			fullMark: 100,
		},
		{
			subject: getTranslation('selfInspection.id_1'),
			A: (points.selfInspection / maxPoints.selfInspection) * 100,
			fullMark: 100,
		},
	]
	// console.log(graphData)

	const summaryData = [
		getTranslation('marketing.title') +
			': ' +
			points.marketing +
			'/' +
			maxPoints.marketing,
		getTranslation('economic.title') +
			': ' +
			points.economic +
			'/' +
			maxPoints.economic,
		getTranslation('legal.title') + ': ' + points.legal + '/' + maxPoints.legal,
		getTranslation('development.title') +
			': ' +
			points.development +
			'/' +
			maxPoints.development,
		getTranslation('leadership.title') +
			': ' +
			points.leadership +
			'/' +
			maxPoints.leadership,
		getTranslation('selfInspection.title') +
			': ' +
			points.selfInspection +
			'/' +
			maxPoints.selfInspection,
		getTranslation('max_points') +
			': ' +
			Object.values(points).reduce((preVal, curVal) => {
				return preVal + curVal
			}) +
			'/' +
			Object.values(maxPoints).reduce((preVal, curVal) => {
				return preVal + curVal
			}),
	]

	const downloadPDF = async () => {
		// scrolling up is necessary in order for the PDF to load correctly
		await window.scrollTo({
			top: 0,
			left: 0,
		})

		// Select and clone elements that are to be edited for the PDF
		const element = document.getElementById('summary').cloneNode(true)
		const lastPage = document.getElementById('last-pdf-page').cloneNode(true)

		// Style settings for cloned elements
		// PDF page size: [215.9mm x 279.4mm]
		lastPage.style.height = '972px'
		element.style.backgroundImage = `url(${entrefox_pdf_bg})`
		element.style.backgroundSize = '100% 279.4mm'
		element.style.backgroundRepeat = 'repeat-y'
		element.style.padding = '15px 100px 0px 100px'

		// Options for the html2pdf rendering
		const opt = {
			filename: 'entrefox_summary.pdf',
			image: { type: 'jpeg' },
			html2canvas: {
				allowTaint: true,
				scale: 1.25,
				scrollX: -window.scrollX,
				scrollY: -window.scrollY,
				windowWidth: document.documentElement.offsetWidth,
				windowHeight: document.documentElement.offsetHeight,
			},
			jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
			pagebreak: { mode: ['avoid-all', 'css', 'legacy', 'whiteline'] },
		}

		// Generate the PDF from the defined options
		window.open(await html2pdf().from(element).set(opt).output('bloburl'))

		// Change the padding back after PDF has been generated
		lastPage.style.height = 'auto'
		element.style.padding = ''
		element.style.backgroundImage = ''
		element.style.backgroundSize = ''
	}

	return (
		<Container className={classes.survey} maxWidth='md'>
			<ButtonHandler
				text={getTranslation('go_back')}
				colors={{ bg: '#cddc39', bgHover: '#c0ca33' }}
				handlePagination={handleFormSubmit}
			/>
			<Box id='summary'>
				{/* Header with EntreFox logo */}
				<Box my={5}>
					<Typography
						component='h1'
						variant='h3'
						color='primary'
						align='center'
					>
						{getTranslation('skills_summary_title.entrepreneur')}
					</Typography>
					<Typography variant='h4' component='h1' align='center' gutterBottom>
						{getTranslation('skills_summary_title.skills_summary')}
					</Typography>
					<Typography align='justify'>
						{getTranslation('summary_description')}
					</Typography>
					<Box align='center'>
						<img
							src={osaamiskartoituksen_koonti}
							className={classes.headingImage}
						/>
					</Box>
					<Typography variant='h6' align='center'>
						{getTranslation('form_done_on')}
						{currentDate}.
					</Typography>
				</Box>
				<Divider />
				<div className='html2pdf__page-break'></div>

				<Box mt={3}>
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('professional_skills')}
					</Typography>
					{/* Questions 5-6 */}
					<Box>
						<GoalsPaper answers={formData[0].answers} />
					</Box>
				</Box>

				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>

				<Box mt={3}>
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('marketing.title')}
					</Typography>
					{/* Questions 5-6 */}
					<Typography my={5} variant='body1'>
						<Box mt={2}>{marketingFeedback(points.marketing)}</Box>
					</Typography>
					{/* Chart for questions */}
					<Box mt={5} mx={5}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								marginBottom: '5%',
							}}
						>
							<NumberGauge
								answer={points.marketing}
								maxAnswer={maxPoints.marketing}
								title={getTranslation('marketing.title')}
								page={currentPage}
							/>
						</div>
					</Box>
				</Box>
				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>
				<Box mt={3}>
					{/* Economics page */}
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('economic.title')}
					</Typography>
					<Typography my={5} variant='body1'>
						<Box mt={2}>{economicFeedback(points.economic)}</Box>
					</Typography>
					<Box mt={5} mx={5}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								marginBottom: '5%',
							}}
						>
							<NumberGauge
								answer={points.economic}
								maxAnswer={maxPoints.economic}
								title={getTranslation('economic.title')}
								page={currentPage}
							/>
						</div>
					</Box>
				</Box>
				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>
				<Box mt={3}>
					{/* Economics page */}
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('legal.title')}
					</Typography>
					<Typography my={5} variant='body1'>
						<Box mt={2}>{legalFeedback(points.legal)}</Box>
					</Typography>
					<Box mt={5} mx={5}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								marginBottom: '5%',
							}}
						>
							<NumberGauge
								answer={points.legal}
								maxAnswer={maxPoints.legal}
								title={getTranslation('legal.title')}
								page={currentPage}
							/>
						</div>
					</Box>
				</Box>
				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>
				<Box mt={3}>
					{/* Economics page */}
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('development.title')}
					</Typography>
					<Typography my={5} variant='body1'>
						<Box mt={2}>{developmentFeedback(points.development)}</Box>
					</Typography>
					<Box mt={5} mx={5}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								marginBottom: '5%',
							}}
						>
							<NumberGauge
								answer={points.development}
								maxAnswer={maxPoints.development}
								title={getTranslation('development.title')}
								page={currentPage}
							/>
						</div>
					</Box>
				</Box>
				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>
				<Box mt={3}>
					{/* Economics page */}
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('leadership.title')}
					</Typography>
					<Typography my={5} variant='body1'>
						<Box mt={2}>{leadershipFeedback(points.leadership)}</Box>
					</Typography>
					<Box mt={5} mx={5}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								marginBottom: '5%',
							}}
						>
							<NumberGauge
								answer={points.leadership}
								maxAnswer={maxPoints.leadership}
								title={getTranslation('leadership.title')}
								page={currentPage}
							/>
						</div>
					</Box>
				</Box>
				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>
				<Box mt={3}>
					{/* Economics page */}
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('selfInspection.title')}
					</Typography>
					<Typography my={5} variant='body1'>
						<Box mt={2}>{selfInspectionFeedback(points.selfInspection)}</Box>
					</Typography>
					<Box mt={5} mx={5}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								marginBottom: '5%',
							}}
						>
							<NumberGauge
								answer={points.selfInspection}
								maxAnswer={maxPoints.selfInspection}
								title={getTranslation('selfInspection.title')}
								page={currentPage}
							/>
						</div>
					</Box>
				</Box>
				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>
				{/* Final page, containing the radar chart and summary*/}
				<Box my={10} id='last-pdf-page'>
					<Typography variant='h5' className={classes.heading}>
						{getTranslation('skills_summary')}
					</Typography>
					<SummaryScroll answers={summaryData} graphData={graphData} />
				</Box>
			</Box>
			<Box mt={2}>
				<Grid
					container
					direction='row'
					justify='space-between'
					alignItems='center'
				>
					<Grid item xs={12} sm={3}>
						<Box>
							<ButtonHandler
								text={getTranslation('download_pdf')}
								colors={{ bg: '#cddc39', bgHover: '#c0ca33' }}
								startIcon={<GetAppRoundedIcon />}
								handlePagination={downloadPDF}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Box mt={2} mb={1}>
							<ButtonHandler
								href='https://www.entrefox.fi/yrittajan-osaamiskartoitus/'
								text={getTranslation('end_skills_form')}
								startIcon={<CheckCircleOutlineRoundedIcon />}
								colors={{
									bg: '#ffeb3b',
									bgHover: '#fbc02d',
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

Summary.propTypes = {
	handleFormSubmit: PropTypes.func,
}

export default Summary

import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from '../contexts/FormContext'
import { getAnswerByID } from '../utils'
import {
	marketingFeedback,
	developmentFeedback,
	economicFeedback,
	legalFeedback,
	leadershipFeedback,
	selfInspectionFeedback,
} from '../data/feedback functions'

// Summary components
import ButtonHandler from './ButtonHandler'
// import SummaryScroll from './summaryComponents/SummaryPaper'
/* import ChartBars from './summaryComponents/ChartBars' */
import NumberGauge from './summaryComponents/NumberGauge'
/* import TextGauge from './summaryComponents/TextGauge' */
// import GoalsPaper from './summaryComponents/GoalsPaper'

// Material UI
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import useStyles from '../styles'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'

// Images
/* import entrefox_business from '../images/summaryImages/entrefox_business.png' */
import jumping from '../images/summaryImages/osaamiskartoitus-koonti.png'
// import business from '../images/summaryImages/entrefox_business.png'
// import steps from '../images/summaryImages/entrefox_steps.png'
// import stocks from '../images/summaryImages/entrefox_stocks.png'
import entrefox_pdf_bg from '../images/background/pdf_background.png'

import html2pdf from 'html2pdf.js'
// import dict from '../data/question_dictionary'
/* import StickyNote from './summaryComponents/StickyNote' */
import { useTranslation } from 'react-i18next'

// Convert date to dd.MM.YYYY format
const dateToYMD = date => {
	return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

const Summary = ({ handleFormSubmit }) => {
	const { t: getTranslation } = useTranslation('general')
	const { formData, currentPage } = useForm()
	const classes = useStyles()
	/* 	const { formData } = useForm() */
	/* 	const sliderValue = dict[getAnswerByID(2, 1)] */
	// Get todays date
	const currentDate = dateToYMD(new Date())
	// const formAnswers = formData[currentPage - 1].answers

	const allSummaryImages = {
		1: jumping,
		2: jumping,
		3: jumping,
		4: jumping,
		5: jumping,
		6: jumping,
	}
	const allFormNames = [
		'',
		getTranslation('marketing.title'),
		getTranslation('economic.title'),
		getTranslation('legal.title'),
		getTranslation('development.title'),
		getTranslation('leadership.title'),
		getTranslation('selfInspection.title'),
	]
	const allKeyNames = [
		'',
		'marketing',
		'economic',
		'legal',
		'development',
		'leadership',
		'selfInspection',
	]
	const formName = allFormNames[currentPage - 1]

	// console.log('formdata', formAnswers)
	// Returns a sum of all the choiceID's which are then later deducted from the overall sum of the highest choiceID's
	// EXAMPLE: choices=4, questions=8, sum=32(+8 because user cant get 0/32),
	// if user answers all questions with choiceID=1, which is worth 4 points, sum=8*1=8, and the total points the user got is ofc 40-8= 32/32

	// Calculate the amount of points user got from their choices from questions
	const totalPoints = page => {
		return formData[page - 1].answers.reduce((prev, cur) => {
			if (cur.id !== 0) {
				// console.log(prev, 'red', page, cur.id, getAnswerByID(page, cur.id))
				return prev + getAnswerByID(page, cur.id)
			}
			return 0
		}, 0)
	}

	const maxPoints = {
		marketing: (formData[1].answers.length - 1) * 4,
		economic: (formData[2].answers.length - 1) * 4,
		legal: (formData[3].answers.length - 1) * 4,
		development: (formData[4].answers.length - 1) * 4,
		leadership: (formData[5].answers.length - 1) * 4,
		selfInspection: 5 * 3 + 3 * 4, // this has five 3 point choice questions and three worth 4 points.
	}

	const points = {
		marketing:
			maxPoints.marketing - totalPoints(2) + formData[1].answers.length - 1,
		economic:
			maxPoints.economic - totalPoints(3) + formData[2].answers.length - 1,
		legal: maxPoints.legal - totalPoints(4) + formData[3].answers.length - 1,
		development:
			maxPoints.development - totalPoints(5) + formData[4].answers.length - 1,
		leadership:
			maxPoints.leadership - totalPoints(6) + formData[5].answers.length - 1,
		selfInspection:
			maxPoints.selfInspection -
			totalPoints(7) +
			formData[6].answers.length -
			1,
	}

	// console.log(maxPoints.legal, totalPoints(4), formData[3].answers.length - 1)
	// console.log('ti', currentPage,
	// 	allKeyNames[currentPage - 1],
	// 	points[allKeyNames[currentPage - 1]],
	// 	maxPoints[allKeyNames[currentPage - 1]]
	// )
	// console.log(points)
	// console.log(maxPoints)

	const downloadPDF = async () => {
		// scrolling up is necessary in order for the PDF to load correctly
		await window.scrollTo({
			top: 0,
			left: 0,
		})

		// Select and clone elements that are to be edited for the PDF
		const element = document.getElementById('summary').cloneNode(true)
		// const lastPage = document.getElementById('last-pdf-page').cloneNode(true)

		// Style settings for cloned elements
		// PDF page size: [215.9mm x 279.4mm]
		// lastPage.style.height = '972px'
		element.style.backgroundImage = `url(${entrefox_pdf_bg})`
		element.style.backgroundSize = '100% 279.4mm'
		element.style.backgroundRepeat = 'repeat-y'
		element.style.padding = '15px 100px 0px 100px'

		// Options for the html2pdf rendering
		const opt = {
			filename: 'entrefox_summary.pdf',
			image: { type: 'jpeg' },
			html2canvas: {
				allowTaint: true,
				scale: 1.25,
				scrollX: -window.scrollX,
				scrollY: -window.scrollY,
				windowWidth: document.documentElement.offsetWidth,
				windowHeight: document.documentElement.offsetHeight,
			},
			jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
			pagebreak: { mode: ['avoid-all', 'css', 'legacy', 'whiteline'] },
		}

		// Generate the PDF from the defined options
		window.open(await html2pdf().from(element).set(opt).output('bloburl'))

		// Change the padding back after PDF has been generated
		// lastPage.style.height = 'auto'
		element.style.padding = ''
		element.style.backgroundImage = ''
		element.style.backgroundSize = ''
	}

	const feedbackSwitch = () => {
		const getPoints = points[allKeyNames[currentPage - 1]]
		switch (currentPage - 1) {
			case 1:
				return marketingFeedback(getPoints)
			case 2:
				return developmentFeedback(getPoints)
			case 3:
				return economicFeedback(getPoints)
			case 4:
				return legalFeedback(getPoints)
			case 5:
				return leadershipFeedback(getPoints)
			case 6:
				return selfInspectionFeedback(getPoints)
		}
	}
	return (
		<Container className={classes.survey} maxWidth='md'>
			<ButtonHandler
				text={getTranslation('go_back')}
				colors={{ bg: '#cddc39', bgHover: '#c0ca33' }}
				handlePagination={handleFormSubmit}
			/>
			<Box id='summary'>
				{/* Header with EntreFox logo */}
				<Box my={5}>
					<Typography
						component='h1'
						variant='h3'
						color='primary'
						align='center'
					>
						{getTranslation('skills_summary_title.entrepreneur')}
					</Typography>
					<Typography variant='h4' component='h1' align='center' gutterBottom>
						{getTranslation('skills_summary_title.skills_summary')}
					</Typography>
					<Typography align='justify'>
						{getTranslation('summary_description')}
					</Typography>
					<Box align='center'>
						<img
							src={allSummaryImages[currentPage - 1]}
							className={classes.headingImage}
						/>
					</Box>
					<Typography variant='h6' align='center'>
						{getTranslation('form_done_on')}
						{currentDate}.
					</Typography>
				</Box>
				<Divider />
				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>

				<Box mt={3}>
					<Typography variant='h5' className={classes.heading}>
						{formName}
					</Typography>
					{/* Questions 5-6 */}
					<Typography my={5} variant='body1'>
						<Box mt={2}>{feedbackSwitch}</Box>
					</Typography>
					{/* Chart for questions */}
					<Box mt={5} mx={5}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								marginBottom: '5%',
							}}
						>
							<NumberGauge
								answer={points[allKeyNames[currentPage - 1]]}
								maxAnswer={maxPoints[allKeyNames[currentPage - 1]]}
								title={formName}
								page={currentPage}
							/>
						</div>
					</Box>
					<Typography variant='h6' align='center'>
						{points[allKeyNames[currentPage - 1]]}/
						{maxPoints[allKeyNames[currentPage - 1]]}
					</Typography>
				</Box>
				{/* Manual page-break for the PDF generation */}
				<div className='html2pdf__page-break'></div>
			</Box>
			<Box mt={2}>
				<Grid
					container
					direction='row'
					justify='space-between'
					alignItems='center'
				>
					<Grid item xs={12} sm={3}>
						<Box>
							<ButtonHandler
								text={getTranslation('download_pdf')}
								colors={{ bg: '#cddc39', bgHover: '#c0ca33' }}
								startIcon={<GetAppRoundedIcon />}
								handlePagination={downloadPDF}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Box mt={2} mb={1}>
							<ButtonHandler
								href='https://www.entrefox.fi/yrittajan-osaamiskartoitus/'
								text={getTranslation('end_skills_form')}
								startIcon={<CheckCircleOutlineRoundedIcon />}
								colors={{
									bg: '#ffeb3b',
									bgHover: '#fbc02d',
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

Summary.propTypes = {
	handleFormSubmit: PropTypes.func,
}

export default Summary
